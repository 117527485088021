import React from "react";
import { useQuery } from "@apollo/client";
import Layout from "components/Layout";
import TimelineView from "components/TimelineView";
import ScheduleDelivery from "components/Delivery/ScheduleDelivery";
import LoadingScreen from "components/LoadingScreen";
import ClientOrderQuery from "./ClientOrderQuery.gql";
import ClientPortalConfigQuery from "./ClientPortalConfigQuery.gql";
import { ORDER_STATUS } from "utils/constants";

function parseTime(timeStr: string): Date {
  const [hours, minutes, seconds] = timeStr.split(":").map(Number);
  const date = new Date();
  date.setHours(hours);
  date.setMinutes(minutes);
  date.setSeconds(seconds);
  return date;
}

const Delivery = () => {
  const { loading, data: clientOrderData } = useQuery(ClientOrderQuery, {
    fetchPolicy: "network-only",
  });

  const { loading: configLoading, data: clientPortalConfigData } = useQuery(
    ClientPortalConfigQuery,
    {
      fetchPolicy: "network-only",
    }
  );

  const isLoading = React.useMemo<boolean>(() => {
    return loading || configLoading;
  }, [loading, configLoading]);

  const isOrderCancelled = React.useMemo<boolean>(
    () => clientOrderData?.clientOrder?.status === ORDER_STATUS.CANCELLED,
    [clientOrderData]
  );
  const isOrderWaitingForStock = React.useMemo<boolean>(
    () => clientOrderData?.clientOrder?.is_waiting_for_stock,
    [clientOrderData]
  );
  let isTimelineDisplayed =
    clientOrderData?.clientOrder?.delivery?.status &&
    clientOrderData?.clientOrder?.status === ORDER_STATUS.VALIDATED;

  if (
    clientOrderData?.clientOrder?.timeSlot?.date &&
    clientPortalConfigData?.clientPortalConfig?.contractorConfig?.order_gap &&
    clientPortalConfigData?.clientPortalConfig?.contractorConfig
      ?.next_day_shift_hour
  ) {
    const deliveryDate = new Date(clientOrderData?.clientOrder?.timeSlot.date);
    const orderGapDays =
      clientPortalConfigData?.clientPortalConfig?.contractorConfig?.order_gap;
    const nextDayShiftHours = parseTime(
      clientPortalConfigData?.clientPortalConfig?.contractorConfig
        ?.next_day_shift_hour
    );
    let cutOff = new Date(clientOrderData?.clientOrder?.timeSlot.date);
    // Subtract the order gap days
    cutOff.setDate(deliveryDate.getDate() - orderGapDays);

    // Add the next day shift hours
    cutOff.setHours(nextDayShiftHours.getHours());
    cutOff.setMinutes(0);

    // Check if the current date and time is past the calculated date and time
    if (new Date() > cutOff) {
      isTimelineDisplayed = true;
    }
  }
  if (
    isLoading ||
    !clientOrderData ||
    !clientPortalConfigData ||
    !clientOrderData.clientOrder ||
    !clientPortalConfigData.clientPortalConfig
  ) {
    return <LoadingScreen />;
  }

  if (isOrderCancelled) {
    return (
      <Layout
        order={clientOrderData.clientOrder}
        clientPortalConfig={clientPortalConfigData.clientPortalConfig}
      >
        <div className="cancelled">
          La livraison a été annulée, merci de vous rapprocher de votre magasin
        </div>
      </Layout>
    );
  } else if (isOrderWaitingForStock) {
    return (
      <Layout
        order={clientOrderData.clientOrder}
        clientPortalConfig={clientPortalConfigData.clientPortalConfig}
      >
        <div className="cancelled">
          Un ou plusieurs produits de votre commande ne sont pas disponibles,
          merci de vous rapprocher de votre magasin{" "}
          {clientOrderData.clientOrder.store_name} pour obtenir plus de
          renseignements
        </div>
      </Layout>
    );
  } else {
    return (
      <Layout
        order={clientOrderData.clientOrder}
        clientPortalConfig={clientPortalConfigData.clientPortalConfig}
      >
        <div className="delivery">
          {isTimelineDisplayed ? (
            <TimelineView delivery={clientOrderData.clientOrder.delivery} />
          ) : (
            <ScheduleDelivery order={clientOrderData.clientOrder} />
          )}
        </div>
      </Layout>
    );
  }
};

export default Delivery;
